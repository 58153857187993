import { useEffect, useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { useGroup, useHandleSignIn, usePermissions, useIsAuthenticated } from 'contexts/Authorization/Context';
import { accessFragment } from 'contexts/Authorization/Context.queries';
import { Crumb } from 'contexts/Breadcrumbs/Context';
import { PublicLayout } from 'layouts';
import { useRouter } from 'next/router';
import * as Yup from 'yup';

import { ApiErrors, Button, Form, InputField, LineHorizontalSpacer, Link } from 'components';

import { useQueryString } from 'utils/hooks/useQueryString';
import { getItem, setItem } from 'utils/functions/localStorage';
import { getErrorMessages } from 'utils/functions/handleApiError';
import { Client } from 'graphql/client';
import { getRole, isTrialExpired, useLazyQuery } from 'utils';

const crumbs: Crumb[] = [
  {
    title: 'Register School',
    route: '/register/school',
  },
];

const getSmartCode = gql`
  query ($projectId: String!, $code: String!) {
    getSmartCode(projectId: $projectId, code: $code) {
      code
      groups {
        customFields {
          customFieldId
          ... on CustomFieldResponseTextGraphType {
            text
          }
        }
      }
    }
  }
`;
const ssoAuthenticate = gql`
  query authenticateSso($code: String!, $smartCode: String!) {
    loginWithSingleSignOn(code: $code, smartCode: $smartCode) {
      ...userInformationFragment
    }
  }
  ${accessFragment}
`;

const signInQuery = gql`
  query SignInQuery($password: String!, $email: String!) {
    login(login: $email, password: $password) {
      ...userInformationFragment
    }
  }
  ${accessFragment}
`;

const SMART_CODE_LOCAL_STORAGE = 'SmartCode';
function Home() {
  const group = useGroup();
  const { push } = useRouter();
  const { code, groupCode } = useQueryString();
  const permissions = usePermissions();
  const isAuthenticated = useIsAuthenticated();
  const client = useRef(Client('gelada'));

  function isTrialExpired(login) {
    const {
      user: { customFields },
    } = login;

    if (customFields) {
      const endTrialPeriod = customFields.find((r) => r.customFieldId === 28)?.dateTime;
      if (endTrialPeriod) {
        const endTrial = new Date(endTrialPeriod);
        if (endTrial.getTime() < new Date().getTime()) {
          return false;
        }
      }
    }
    return true;
  }

  if (group && isAuthenticated) {
    const roleType = getRole(permissions, group.id);
    const isStudent = roleType === 'Student';
    push(isStudent ? '/student' : '/teacher');
  }

  const [currentErrorMessages, setCurrentErrorMessages] = useState([]);
  const signIn = useHandleSignIn();

  function handleError(error) {
    const errorMessages = getErrorMessages(error);

    // eslint-disable-next-line no-console
    errorMessages.map((errorMessage) => console.error(errorMessage));
    setCurrentErrorMessages(errorMessages);
  }

  const [sendGetSmartCodeQuery, { error: errorGetSmartCode, loading: isGetSmartCodeLoading }] = useLazyQuery(getSmartCode, {
    client: client.current,
    anon: true,
    fetchPolicy: 'no-cache',
    onCompleted({ getSmartCode }) {
      const { groups, code } = getSmartCode;
      const [group] = groups;
      const { customFields } = group;
      const response = customFields.find((r) => r.customFieldId === 29);
      if (!response) {
        return;
      }
      const { text } = response;
      setItem(SMART_CODE_LOCAL_STORAGE, code);
      window.location.href = `https://ourfutures-sso.auth.ap-southeast-2.amazoncognito.com/authorize?response_type=code&identity_provider=${text}&client_id=7tsq9jds1m7i8v7n3thorvssk2&redirect_uri=https://app.ourfutures.education`;
    },
    onError: handleError,
  });

  const [sendSsoAuthenticate, { error: errorSsoAuthenticate, loading: isSsoAuthenticateLoading }] = useLazyQuery(ssoAuthenticate, {
    client: client.current,
    anon: true,
    fetchPolicy: 'no-cache',
    onCompleted({ loginWithSingleSignOn }) {
      if (!isTrialExpired(loginWithSingleSignOn)) {
        setCurrentErrorMessages([
          'Your 2 week trial has expired, please contact OurFutures to upgrade your account or extend your trial access.',
        ]);
        return;
      }
      signIn(loginWithSingleSignOn);
    },
    onError: handleError,
  });

  const [sendSignInQuery, { error, loading: isSignInLoading }] = useLazyQuery(signInQuery, {
    client: client.current,
    anon: true,
    onCompleted(data) {
      const { login } = data;
      if (!isTrialExpired(login)) {
        setCurrentErrorMessages([
          'Your 2 week trial has expired, please contact OurFutures to upgrade your account or extend your trial access.',
        ]);
        return;
      }
      signIn(login);
    },
    onError: handleError,
  });

  function handleSubmit({ login, password }) {
    sendSignInQuery({
      variables: { email: login, password },
    });
  }

  useEffect(() => {
    if (!code) {
      return;
    }
    const smartCode = getItem(SMART_CODE_LOCAL_STORAGE);
    if (!smartCode) {
      return;
    }
    sendSsoAuthenticate({ variables: { code, smartCode } });
  }, [code]);

  useEffect(() => {
    if (!groupCode) {
      return;
    }
    sendGetSmartCodeQuery({ variables: { projectId: process.env.REACT_APP_PROJECT_ID, code: groupCode } });
  }, [groupCode]);

  const isLoading = isGetSmartCodeLoading || isSignInLoading || isSsoAuthenticateLoading;

  return (
    <PublicLayout title="Login" description="Login" crumbs={crumbs}>
      <section className="l-full-width l-position--relative h-background--color-grey-300 h-overflow--hidden c--login-section">
        <img
          className="l-position--centred h-no-left h-with-padding h-desktop-large-only p-register__thumbpin"
          src="/images/thumbpin-group-secondary.svg"
          alt=""
        />
        <img className="l-position--centred h-desktop-only p-register__ring--secondary " src="/images/ring-grey.svg" alt="" />
        <img className="l-position--centred h-desktop-only p-register__ring--white" src="/images/ring-white.svg" alt="" />
        <div className="l-container l-container--medium l-responsive-grid l-position--relative">
          <div className="w-50 l-responsive-grid__item--8-col l-flex l-flex--column l-flex--justify-center h-with-x-padding h-background--color-grey-300">
            <h1 className="h-with-y-margin">Login</h1>
            <Form
              className="p-register__form"
              id="school_register"
              initialValues={{
                login: '',
                password: '',
              }}
              validationSchema={Yup.object().shape({
                login: Yup.string().label('Login').required('is required'),
                password: Yup.string().label('Password').required('is required'),
              })}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => {
                return (
                  <>
                    <fieldset className="l-flex l-flex--column">
                      <InputField name="login" className="l-full-width" type="email" label="Email address" autoComplete="email" required />
                      <InputField
                        orientation="row"
                        name="password"
                        className="l-full-width"
                        type="password"
                        label="Password"
                        autoComplete="new-password"
                        required
                      />
                    </fieldset>

                    <div className="l-flex h-with-y-margin l-flex--justify-space-between l-full-width">
                      <LineHorizontalSpacer />
                      <Button id="login-button" type="submit" icon="arrow" rotateIcon="270" isLoading={isLoading} isDisabled={isLoading}>
                        Login
                      </Button>
                    </div>
                  </>
                );
              }}
            </Form>
            <div className="l-flex c-typography--light">
              <Link className="l-flex__item--row" href="/user/forgot-password">
                Forgot password
              </Link>
              <Link className="l-flex__item--row registration" href="/register">
                Register
              </Link>
            </div>
          </div>
        </div>
        <div className="w-50 l-responsive-grid__item--8-col l-flex--justify-center l-position-h-center">
          <ApiErrors errorMessages={currentErrorMessages} />
        </div>
      </section>
    </PublicLayout>
  );
}

export default Home;
